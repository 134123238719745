const service_info = {
    "Stair cases":
        {
            title: "Stair cases",
            content: [{description: "Custom made Solid hard wood stair case ", img_urls: ["staircase_1.jpg","staircase_2.jpg", "staircase_3.jpg","staircase_4.jpg",
                    "staircase_5.jpg","staircase_6.jpg", "staircase_7.jpg"]},

            ]

        },
    "Pivot doors":
        {
            title: "Pivot doors",
            content: [
                {description:"Solid timber pivot door + smart lock", img_urls: ["pivotdoor_14.jpg","pivotdoor_15.jpg"]},
                {description:"Full solid custom made pivot doors",img_urls:["pivotdoor_1.jpg",]},
                {description:"Custom made solid timber pivot door", img_urls: ["pivot-custom-solid-1.jpg","pivot-custom-solid-2.jpg"]},
                {description:"Custom made jumbo pivot doors",img_urls:["pivotdoor_3.jpg", "pivotdoor_4.jpg",
                        "pivotdoor_5.jpg","pivotdoor_6.jpg","pivotdoor_9.jpg","pivotdoor_10.jpg",]},
                {description:"Full solid pivot door & smart lock",img_urls:["pivotdoor_8.jpg",]},
                {description: "Luxury solid timber pivot door",img_urls: ["pivotdoor_11.jpg","pivotdoor_12.jpg","pivotdoor_13.jpg"]}

            ]

        },
    "Entrance & Internal doors":
        {
            title: "Entrance doors",
            content: [
                {description:"Standard Entrance Door",img_urls:["entrancedoor_1.jpg", "entrancedoor_2.jpg","entrancedoor_3.jpg"]},
                {description:"Custom made unique design internal hollow door ",img_urls:["internal_1.jpg","internal_2.jpg"]},
                {description:"Jumbo internal door +classic lock",img_urls:["jumboInternal1.jpg", "jumboInternal2.jpg","jumboInternal3.jpg","jumboInternal4.jpg"]},
                {description:"Flush Gyprocker door jambs, frameless finish ",img_urls:["internal_3.jpg","internal_4.jpg",]},
                {description:"Solid timber French door with crispy finish",img_urls:["entrancedoor_4.jpg","entrancedoor_5.jpg","entrancedoor_6.jpg","entrancedoor_7.jpg"]},

            ]

        },
    "Security doors & One way mesh privacy doors":
        {
            title: "Security doors & One way mesh privacy doors",
            content: [
                {description:"Securiy Hinge door",img_urls:["securitydoor_1.jpg","securitydoor_5.jpg","securitydoor_9.jpg",
                        "securitydoor_10.jpg","securitydoor_11.jpg","securitydoor_12.jpg","securitydoor_13.jpg","securitydoor_14.jpg"]},
                {description:"316 stainless steel sliding security screen door",img_urls:
                        ["securitydoor_2.jpg","securitydoor_3.jpg","securitydoor_4.jpg","securitydoor_15.jpg","securitydoor_16.jpg","securitydoor_17.jpg"]},
                {description:"316 stainless steel screen crimsafe 3 point triple locks",img_urls:
                        ["securitydoor_15.jpg","securitydoor_16.jpg","securitydoor_17.jpg"]},
                {description:"Double security stainess steel doors+soft closer",img_urls:
                        ["double_soft_sec_1.jpg","double_soft_sec_2.jpg","double_soft_sec_3.jpg","double_soft_sec_4.jpg",
                            "double_soft_sec_5.jpg","double_soft_sec_6.jpg","double_soft_sec_7.jpg","double_soft_sec_8.jpg"]},
                {description:"Aluminium one way vision privacy mesh",img_urls: ["alu_oneway_1.jpg","alu_oneway_2.jpg","alu_oneway_2_inside.jpg"]},
                // {description:"Aluminium hinge door",img_urls: ["alu_hinge_1.jpg","alu_hinge_2.jpg"]},
                {description:"Double side security door",img_urls:["securitydoor_6.jpg","securitydoor_7.jpg"]},
                {description:"Stainless steel security screen door+pet door",img_urls:["securitydoor_8.jpg"]},

            ]

        },
    "Stacker doors":
        {
            title: "Stacker doors",
            content: [
                {description:"Aluminium commerical grade 150mm thickness frame, 5 tracks + security doors system",
                    img_urls:["5stacksecurity_1.jpg","5stacksecurity_2.jpg","5stacksecurity_3.jpg","stackerdoor_3.jpg","stackerdoor_4.jpg"]},
                {description:"3 Panels+5 TRACKS stacking door",img_urls:["stackerdoor_1.jpg"]},
                {description:"Triple stacking cavity slider",img_urls:["stackerdoor_2.jpg"]},


            ]

        },
    "Bi fold doors":
        {
            title: "Bi fold doors",
            content: [
                {description:"3 panels bi fold doors",img_urls:["bifold_1.jpg","bifold_2.jpg"]},
                {description:"5 panels bi fold doors",img_urls:["bifold_3.jpg","bifold_4.jpg"]},
                {description:"timber bi fold door set ",img_urls:["timber_bifold.jpg","timber_bifold_2.jpg","timber_bifold_3.jpg"]},

            ]

        },
    "Sliding windows":
        {
        title: "Sliding windows",
        content: [
            {description:"Commercial 100mm sliding window",img_urls:["slidingwindow_5.jpg","slidingwindow_6.jpg","slidingwindow_7.jpg"]},
            {description:"Residential grade 67mm sliding window + timber reveal",img_urls:["slidingwindow_2.jpg","slidingwindow_3.jpg","slidingwindow_4.jpg",
                    "slidingwindow_8.jpg","slidingwindow_9.jpg","slidingwindow_10.jpg","slidingwindow_11.jpg","slidingwindow_12.jpg",]},
            {description:"Close off garage with commercial grade sliding window",img_urls:["slidingwindow_1.jpg"]},
            {description:"Commercial grade 100mm  sliding window",img_urls:["slidingwin_1.jpg"]},


        ]

    },
    "Sliding doors":
        {
            title: "Sliding doors",
            content: [
                {description: "Shop front aluminium commercial grade sliding door",img_urls: ["shop_front_1.jpg","shop_front_2.jpg"]},
                {description:"100mm commercial grade sliding door",img_urls:["slidingdoor_1.jpg","slidingdoor_2.jpg"]},
                {description:"Enclosure Alfresco with commercial grade 100mm sliding door",img_urls:["slidingdoor_13.jpg","slidingdoor_14.jpg",
                        "slidingdoor_3.jpg","slidingdoor_4.jpg","slidingdoor_7.jpg","slidingdoor_8.jpg","slidingdoor_9.jpg","slidingdoor_10.jpg","slidingdoor_15.jpg","slidingdoor_16.jpg"]},
                {description:"Enclosure alfresco with aluminium commercial grade 100mm Jumbo siding door+stainless steel ecurity door",img_urls:["slidingdoor_11.jpg","slidingdoor_12.jpg"]},
                {description:"Double cavity sliders",img_urls:["sliderdoor1.jpg"]},
                {description:"sliding door-garage sliding door",img_urls:["slidingdoor_5.jpg","slidingdoor_6.jpg"]},
                {description: "Alfresco with commercial grade materials+6.38mm glazing.",img_urls: ["alfresco_1.jpg"
                        ,"alfresco_2.jpg","alfresco_3.jpg","alfresco_4.jpg","alfresco_5.jpg","alfresco_6.jpg"]}

            ]

        },
    "Shop front doors":
        {
            title: "Shop front doors",
            content: [

                {description: "Aluminium commercial grade sliding door",img_urls: ["shop_front_1.jpg"]},
                {description: "Double side hinge doors",img_urls: ["double_hinge_1.jpg","shop_front_3.jpg","shop_front_4.jpg","shop_front_5.jpg"]},
                {description: "Custom made hinge door",img_urls: ["shop_front_2.jpg","shop_front_6.jpg","shop_front_7.jpg"]},

            ]

        },
    "Double glazing windows":
        {
            title: "Double glazing windows",
            content: [
                {description: "Double glazing window: 6mm clear toughened+6mm air+6mm clear toughened",img_urls:["double_glazing_window_1.jpg","double_glazing_window_3.jpg","double_glazing_window_4.jpg","double_glazing_window_5.jpg"]},
                {description: "Double glazing window+ Architraves",img_urls:["double_glazing_window_2.jpg"]},
            ]
        }




}

export default service_info;

