import React, { useState, useEffect } from 'react';
import {
    HStack,
    IconButton,
    Image,
    Text,
    Badge,
    Box,
    Heading,
    Button,
    Progress,
    VStack,
    Drawer,
    useDisclosure, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, Link, Divider
} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";
import logo from "../assets/logo.png"
import {HamburgerIcon} from "@chakra-ui/icons";
import SideNavigation from "./SideNavigation";
import NavBarMenu from "../components/NavBarMenu";
import ServiceCard from "../components/ServiceCard";

interface ScrollFunctions{
    scrollfunc: ()=>void;

}


const NavBar = ({scrollfunc_1,scrollfunc}:ScrollFunctions) => {
    const barAni = keyframes`
          from {
        filter: hue-rotate(0deg);
      }
      to {
        filter: hue-rotate(360deg);
      }
    `;
    const bar_ani =`${barAni} 10s ease-in-out infinite;`
    const [process, setprocess] = useState(0)
    window.addEventListener("scroll", (event) => {
        setprocess(Math.ceil((window.scrollY)/(document.body.clientHeight-window.innerHeight) *100))
    });

    function isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()

    const fs = window.innerWidth > 768?"0.8vw":"14px";

    const navbar_link_color = "white";
    const navbar_link_height = "5vh"

    const navbar_links = <>

        {/*<Button h = "4vh" bg={"none"} onClick={()=>{window.scrollTo({*/}
        {/*    top: 0,*/}
        {/*    behavior: 'smooth'*/}
        {/*});onClose()}}>Home</Button>*/}
        { window.innerWidth<768&&
            <Link textDecoration={"underline"} fontSize = {{base:"sm",md:"16px",lg:"20px"}} _hover = {{color:"gold"}}  onClick = {()=>{
                document.getElementById("contact").scrollIntoView({ behavior: 'smooth',block: 'end'});
            }}>Book a free Measure & Quote</Link>}

        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{window.scrollTo(0, 0);onClose()}}>Home</Button>
        {/*<Button borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{scrollfunc("about");onClose()}}>About Us</Button>*/}
        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{scrollfunc("gallery");onClose()}}>Gallery</Button>



        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{openServiceCard("Pivot doors");onClose()}} >Pivot doors</Button>
        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{openServiceCard("Entrance & Internal doors");onClose()}}>Entrance & Internal doors</Button>

        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{openServiceCard("Security doors & One way mesh privacy doors");onClose()}}>Security doors & One way mesh privacy doors</Button>
        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{openServiceCard("Stair cases");onClose()}}>Stair cases</Button>
        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{openServiceCard("Stacker doors");onClose()}}>Stacker doors</Button>
        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{openServiceCard("Shop front doors");onClose()}}>Shop front doors</Button>
        <Button _hover = {{background:"#AD3F45",color:"white"}} borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{openServiceCard("Bi fold doors");onClose()}}>Bi fold doors</Button>

        <NavBarMenu text={"Sliding doors & windows"} items = {["Sliding doors","Sliding windows"]}
                    clickfunc={openServiceCard}></NavBarMenu>
        <NavBarMenu text={"Double glazing"} items = {["Double glazing windows"]}
                    clickfunc={openServiceCard}></NavBarMenu>




    </>

    const [modal_title, set_modal_title] = useState(null);

    function openServiceCard(title){
        set_modal_title(title)
        onOpen2();
    }





    return (
        <>
            <ServiceCard title={modal_title} isOpen={isOpen2} onOpen={onOpen2} onClose={onClose2}/>
            <Box zIndex={"1300"}  opacity={"1"} w = {{base: "100vw", md:"100vw"}}  border={"1px"} borderColor={"black"} borderTop = {0}
                    justifyContent = "space-between" position = "fixed" h = "fit-content" >

                <Box bg = "antiquewhite"  align={"start"} w = {{base: "100vw", md:"100vw"}}>
                    <Box animation = {bar_ani} bg ="green" h='8px'
                         width={`${process}%`} zIndex = "1000"
                         transition={"0.3s ease-in-out"}/>
                </Box>

                <HStack  bg={"white"} display={"flex"} justifyContent={{base:"space-between", md:"center"}} alignItems={"center"} gap={"2vw"} pb={"1vh"}>
                    <Image src={logo} h={"12vh"} pb={"5px"} pt ="5px"></Image>
                    <Text as={"b"} color = "black" fontSize = {{base:"9px",md:"1xl",lg:"20px"}} ml={"20px"}>ABN: 85667 346 652<br/>License Number: 451640C</Text>
                    <Text as={"b"} color = "black" fontSize = {{base:"9px",md:"1xl",lg:"20px"}} ml={"20px"}>Free Quote: 0451 160 499</Text>
                    { window.innerWidth>=768&&
                    <Link textDecoration={"underline"} fontSize = {{base:"sm",md:"20px",lg:"25px"}} _hover = {{color:"gold"}}
                          onClick = {()=>{
                              document.getElementById("contact").scrollIntoView({ behavior: 'smooth',block: 'end'});
                          }}>Book a free Measure & Quote</Link>}
                    {window.innerWidth <= 768 &&
                        //shortcut navbar
                        <IconButton bg = "none" border = "1px" mr = "3vw" icon = {<HamburgerIcon color = "black"/>} onClick={()=>onOpen()} />
                    }
                </HStack>

                { window.innerWidth>768&&
                    //normal navbar
                    <>


                        <HStack borderTop={"1px solid black"} marginInline={{md:"0px"}} h ="fit-content" bg={"white"} display={"flex"} justifyContent={{md:"center"}} alignItems={"end"}>
                            {navbar_links}
                        </HStack>
                        {/*<HStack marginInline={{md:"0px"}} h ="6vh" bg={"white"} display={"flex"} justifyContent={{md:"center"}} alignItems={"end"} >*/}
                        {/*    <Button  borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{window.scrollTo(0, 0);onClose()}}>Home</Button>*/}
                        {/*    <Button borderRadius={0} fontSize = {fs} h={navbar_link_height} bg={navbar_link_color} onClick={()=>{scrollfunc("about");onClose()}}>About Us</Button>*/}
                        {/*    <Button fontSize = {fs} h={navbar_link_height} bg={"none"} onClick={()=>{scrollfunc("gallery");onClose()}}>Gallery</Button>*/}
                        {/*</HStack>*/}
                    </>

                }



            </Box>



            <Drawer
                isOpen={isOpen}
                placement='top'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent bg = "white">
                    <DrawerCloseButton />
                    <DrawerHeader>Menu</DrawerHeader>
                    <VStack>
                        {navbar_links}
                    </VStack>


                </DrawerContent>
            </Drawer>
        </>
    )

}

export default NavBar;